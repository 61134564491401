
import Table from "@/components/common/Table.vue";
import TitleButtons from "@/components/common/TitleButtons.vue";
import { Pagination } from "@/mixins";
import Component, { mixins } from "vue-class-component";

@Component({
  components: { Table, TitleButtons }
})
export default class SignaturePage extends mixins(Pagination) {
  isSingleSelect = false;
  buttonsArray: {
    text: string;
    icon: string;
    color: string;
    activeClass?: string;
    disabled?: boolean;
    action: string;
  }[] = [
    {
      text: "Sign",
      icon: "mdi-checkbox-marked-circle-outline",
      color: "dark",
      action: ""
    },
    {
      text: "Retake",
      icon: "mdi-pencil-box-outline",
      color: "white",
      action: ""
    },
    {
      text: "",
      icon: "mdi-camera-outline",
      color: "white",
      action: ""
    },
    {
      text: "Remove",
      icon: "mdi-delete",
      color: "gray",
      action: ""
    }
  ];
  items = [
    {
      name: "Person name",
      surname: "Person Surname",
      passport: "123456789012345",
      phone: "+12345 678 90 90",
      signatureStatus: 1
    }
  ];
  headers = [
    { text: "Name", align: "left", sortable: true, value: "name" },
    { text: "Surname", align: "center", value: "surname" },
    { text: "ID #/ Passport", align: "center", value: "passport" },
    { text: "Phone", align: "center", value: "phone" },
    { text: "Status", align: "center", value: "signatureStatus" }
  ];
  itemsName = "contacts";

  clearHandler(): void {
    // this.$refs.signaturePad.clearSignature();
  }
  saveHandler(): void {
    // const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
  }
}
